import "./contact.css";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import { FaDirections } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoCloseCircleSharp } from "react-icons/io5";

function Contact() {
  const centre = useMemo(() => {
    return { lat: 51.38278001356345, lng: -0.2821195960163729 };
  }, []);
  const [marker, setMarker] = useState(null);
  const [modal, setModal] = useState(false);
  const form = useRef();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    if (isLoaded) {
      setMarker(centre);
    }
  }, [isLoaded, centre]);

  if (!isLoaded) {
    return <p>Is Loading...</p>;
  }

  function directions() {
    window.open(
      "https://www.google.com/maps/dir//Ak+barbers,+3+Raeburn+Ave,+Surbiton+KT5+9BN/@51.3828447,-0.2820749,19.4z/data=!4m9!4m8!1m0!1m5!1m1!1s0x48760bfc1dfdd039:0x6022e3fb582d70e5!2m2!1d-0.2821266!2d51.3827564!3e0?entry=ttu",
      "_blank"
    );
  }

  function toggleModal() {
    setModal(!modal);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_1nadpa7",
        "template_eupg1kq",
        form.current,
        "QGcga3qMCPVtT-zu0"
      )
      .then((result) => {
        console.log(result.text);
        form.current.reset(); // Clear the form fields
        notify();
      })
      .catch((error) => {
        console.log(error.text);
      });
  };

  const notify = () => {
    toast.success("Message Sent");
  };

  const phoneNumber = "07411175864";

  function callNow() {
    const telLink = `tel:${phoneNumber}`;
    window.location.href = telLink;
  }

  function instagramHandleClick() {
    window.open("https://www.instagram.com/ak_barbers_/", "_blank");
  }

  function tiktokHandleClick() {
    window.open("https://www.tiktok.com/@ak_barberssurbiton", "_blank");
  }

  return (
    <div className="contactOverview" id="contactSection">
      <div className="contactContainer">
        <header className="contactInfo">
          <h1 className="contactTitle">Contact</h1>
          <h2 className="contactSub">Phone</h2>
          <p className="contactDeet">07411175864</p>
          <button className="callBtn" onClick={callNow}>
            {" "}
            Call Now
          </button>
          <h2 className="contactSub">Email</h2>
          <p className="contactDeet"> info.akbarbers@gmail.com</p>
          <button className="emailBtn" onClick={toggleModal}>
            {" "}
            Email us
          </button>
        </header>
        {modal && (
          <div className="modal">
            <div className="modal-overlay" onClick={toggleModal}>
              <div className="modalContent">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="form-container">
                    <label className="contact-name">Name</label>
                    <input
                      className="user-name"
                      type="text"
                      name="user_name"
                      placeholder="Your Name"
                      required
                    />
                    <label className="contact-email">Email</label>
                    <input
                      className="user-email"
                      type="email"
                      name="user_email"
                      placeholder="Your Email"
                      required
                    />
                    <label className="contact-message">Message</label>
                    <textarea
                      className="user-message"
                      name="message"
                      placeholder="Enter Your Message"
                      required
                    />
                    <input
                      className="contact-send"
                      type="submit"
                      value="Send"
                    />
                    <IoCloseCircleSharp
                      className="closeBtn"
                      onClick={toggleModal}
                    >
                      Close
                    </IoCloseCircleSharp>
                  </div>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        )}
        <header className="openingHours">
          <h1 className="openingTitle">Opening Hours</h1>
          <p className="openingDeet">Monday: 9:00am - 7:00pm</p>
          <p className="openingDeet">Tuesday: 9:00am - 7:00pm</p>
          <p className="openingDeet">Wednesday: 9:00am - 7:00pm</p>
          <p className="openingDeet">Thursday: 9:00am - 7:00pm</p>
          <p className="openingDeet">Friday: 9:00am - 7:00pm</p>
          <p className="openingDeet">Saturday: 8:00am - 7:00pm</p>
          <p className="openingDeet">Sunday: 9:00am - 6:00pm</p>
        </header>
        <header className="address-container">
          <h1 className="address-title">Address</h1>
          <p className="contactDeet"> 3 Raeburn Avenue</p>
          <p className="contactDeet"> Surbiton</p>
          <p className="contactDeet"> KT5 9BN</p>
          <p className="contactDeet"> UK</p>
          <div className="directionsBtn">
            <button className="directions" onClick={directions}>
              Get Directions
              <FaDirections className="directionsIcon" />
            </button>
          </div>
        </header>
      </div>
      <p className="openingDeet-mobile">
        *Mobile service available before and after hours. Please call or email
        us for more information.*
      </p>
      <div className="socials">
              <p className="socialsDeet">Follow us on social media</p>
              <span className="socialsIcons">
                <img
                  className="socialsIcon"
                  src="https://img.icons8.com/fluency/50/instagram-new.png"
                  alt="instagram"
                  onClick={instagramHandleClick}
                />
                <img
                  className="socialsIcon"
                  src="https://img.icons8.com/ios-filled/50/tiktok--v1.png"
                  alt="tiktok"
                  onClick={tiktokHandleClick}
                />
              </span>
            </div>
      <div className="map">
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "400px",
            borderRadius: "10px",
            border: "2px",
            alignSelf: "center",
          }}
          zoom={15}
          center={centre}
        >
          {marker && <Marker position={centre} />}
        </GoogleMap>
      </div>
      <div className="copy-rights">
        <p className="copy-right">© 2023 AK Barbers. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Contact;
