import React, { useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { BiSolidQuoteAltLeft } from "react-icons/bi";
import QR from "../../Images/QR.png";
import './testimonials.css';

function Testimonials() {

  const testimonials = [
    {
      name: 'Shervin M',
      testimonial: 'Been going to Arslan for almost 15 years now. I have lived abroad and in other cities and no barber has ever come close to the quality of service and cut that Arslan provides. He is a true professional and a master of his craft. I highly recommend him to anyone looking for a great haircut.'
    },
    {
      name: 'Danyal R',
      testimonial: 'Arslan is not only a great barber but a great person. He is very professional and always makes sure you are satisfied with your haircut. I have been going to him for over 10 years and I have never been disappointed, and I am so happy that he has opened his own shop.'
    },
    {
      name: 'Deen B',
      testimonial: 'Amazing barber. Always looking fresh after a trim. Highly recommend.'
    },
    {
      name: 'Joe',
      testimonial: 'I had a great experience here. The stylist was friendly and really listened to what I wanted. The haircut turned out exactly how I hoped! clean, fresh, and easy to style. The whole process was quick and professional. I’ll definitely be coming back for my next cut!'
    },
      {
        name: 'Roman',
        testimonial: `The best barber I've ever been have been going for the past 10 years. I always get the best customer service always giving me the best trims would definitely recommend to get your haircut from AK best skin fades in town definitely 1000/10!`
      },
      {
        name: 'Daniel',
        testimonial: 'Great barber shop with great barbers they can sort you out and leave you feeling fresh would 100% recommend.'
      },
      {
        name: 'Samo',
        testimonial: 'Amazing experience, definitely worth every penny. Very experienced barbers with a great customer service. Will be back!'
      },
      {
        name: 'Ubaid',
        testimonial: 'Absolutely amazing. Trim was top tier. Beard felt better than it has ever in my life. Reasonable price too and the workers are lovely.'
      },
      {
        name: 'Luke',
        testimonial: `The best in town.
You feel welcomed on arrival and the brothers really make you feel at home in the shop. A brilliant place to get your haircut there’s nothing these guys can’t do with a pair of clippers. If your going anywhere in Surrey for a trim make sure you go see AK you won’t regret it.`
      },
      {
        name: 'Hamzah',
        testimonial: 'Always clean, efficient and organised. Top tier haircuts 👌🏽'
      }
  ]

  // randomly select 5 testimonials
  const randomTestimonials = testimonials.sort(() => Math.random() - 0.5).slice(0, 5);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,         
    autoplaySpeed: 10000,
  };

   // Reference to the React Slick slider
   const sliderRef = useRef(null);

  // Function to go to the previous slide
  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  // Function to go to the next slide
  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const stars = Array.from({ length: 5 }, (_, index) => (
    <img
      key={index}
      src='https://img.icons8.com/ios-filled/50/daa24e/star--v1.png'
      alt={`Star ${index + 1}`}
      className='star'
    />
  ));

  function reviewsHandleClick() {
    window.open('https://www.google.com/localservices/prolist?g2lbs=ANTchaPr6LTVdhSVZhrvOsbYz_z_HqeJKDqoULaBkG8FW8-HgXP2h-ePGw0XoR5Tc5fEKry4y8r8UqOHm0zmXKin_TJbQplbGS2UfuaDCtH_lRvvkK3tVbrR8N69t0TkfCiUR_DnrxuV&hl=en-GB&gl=uk&cs=1&ssta=1&oq=ak%20barbers&src=2&sa=X&q=ak%20barbers%20surbiton&ved=2ahUKEwj3tPX156WDAxVxIQYAHVgwCLMQjdcJegQIABAH&slp=MgBAAVIECAIgAIgBAJoBBgoCFxkQAQ%3D%3D&spp=Cg0vZy8xMWwzMnE5czRsOogBV2hrUUFCQUJJaE5oYXlCaVlYSmlaWEp6SUhOMWNtSnBkRzl1cWdGR0NnZ3ZiUzh3Ym1NNWVoQUJNaDhRQVNJYjE4cnR6cUt1U1hPQVRZYWVCbmp3cUlMcmlpWF9OUlMzMTNnT01oY1FBaUlUWVdzZ1ltRnlZbVZ5Y3lCemRYSmlhWFJ2Ymc9PQ%3D%3D&scp=ChBnY2lkOmJhcmJlcl9zaG9wEgAaACoLQmFyYmVyIHNob3A%3D#ts=3', '_blank');
    }

  return (
    <div id="testimonialsSection" className="testimonials-overview">
      <h1 className="testimonial-title">Testimonials</h1>
      <div className="slider-container">
      <span onClick={goToPrevSlide}><img src='https://img.icons8.com/material-rounded/30/d5a153/chevron-left.png' alt='prev' className='prev'/></span>
      <Slider {...settings} className="slider" ref={sliderRef}>
        {randomTestimonials.map((testimonial, index) => (
          <div key={index}>
            <h3 className="testimonial-name">{testimonial.name}</h3>
            <span className="quote-icon"><BiSolidQuoteAltLeft /></span>
            <p className="testimonial-text">{testimonial.testimonial}</p>
          </div>
        ))}
      </Slider>
      <span onClick={goToNextSlide}><img src='https://img.icons8.com/material-rounded/30/d5a153/chevron-right.png' alt='next' className='next' /></span>
      </div>
      <div className="review">
              <p className="reviewsDeet">Leave us a review</p>
              <img className="reviewsIcon" src={QR} alt="google" onClick={reviewsHandleClick}/>
            </div>
      <div className='stars-div'>
      <div className='stars'>{stars}</div>;
      </div>
    </div>
  );
}

export default Testimonials;

/* 
const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const url = `http://localhost:4000/reviews`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.result && data.result.reviews) {
          setReviews(data.result.reviews);
        } else {
          console.error('No reviews found.');
        }
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, []);

  console.log( reviews);



{reviews.length > 0 ? (
        <Slider {...settings} className="slider" ref={sliderRef}>
          {reviews.map((review, index) => (
            <div key={index}>
              <h3 className="testimonial-name">{review.author_name}</h3>
              <span className="quote-icon"><BiSolidQuoteAltLeft /></span>
              <p className="testimonial-text">{review.text}</p>
            </div>
          ))}
        </Slider>
      ) : (
        <p>Loading reviews...</p>
      )}
*/
